<template>
  <section>
    <el-drawer
      id="playListId"
      :modal="false"
      :show-close="false"
      title="我是标题"
      size="45%"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <div slot="title">
        <section class="title">
          <H2>播放列表({{ musicList.length }}) </H2>
          <div><i>双击歌曲名称开始播放</i> </div>
        </section>
      </div>
      <el-table
        :data="musicList"
        height="90%"
        ref="musicListTable"
        style="width: 98%;margin-bottom:60px"
        @row-dblclick="rowDblclick"
        :row-class-name="tableRowClassName"
      >
      <el-table-column type="index" label="#" width="100"> </el-table-column>
        <el-table-column prop="musicName" label="歌曲名称" :show-overflow-tooltip="true">
          <template slot-scope="scope" >
            <span v-if="scope.row.playIng" style="display:flex">
                <img src="@/assets/images/musicPlayer/playing.png" alt="" style="width:50px; margin-right:6px"> <span class="textColor">{{ scope.row.musicName }}</span>
            </span>
            <span
            v-else
              > {{ scope.row.musicName }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="singer" label="歌手" :show-overflow-tooltip="true" > 
            <template slot-scope="scope">
                <span :class="{textColor:scope.row.playIng}">{{ scope.row.singer }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="duration" label="时长" align="right" header-align="right">
          <template slot-scope="scope">
            <span :class="{textColor:scope.row.playIng}">{{
              `${ getTime(scope.row.duration)}`
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>
  </section>
</template>

<script>
let flag = true
export default {
  props: ["musicList"],
  data() {
    return {
      drawer: false,
      direction: "rtl",
      mTableList:[]
    };
  },
  created() {
    this.drawer = true;
    this.mTableList = this.musicList
  },
  methods: {
    handleClose(done) {
      this.drawer = false;
      this.$emit("close");
    },
     /***
     * 节流函数
     * @func 必传,执行函数
     * @wait 可传,默认值1000,延迟时间
     * @param  可传,函数参数
     * @return {Function} 返回值为函数
     */
     throttle(func, wait = 1000,param) {
      if (flag) {
          flag = false
          setTimeout(() => flag = true, wait)
          return func(...param)
      }
    },
    playSelectMusic(row){
      for(let i = 0 ;i<this.mTableList.length;i++){
         if(i == row.idx){
          this.$set(this.mTableList[i],'playIng',true) 
          this.$set(this.mTableList[i],'musicName',this.mTableList[i].musicName + ' ')
         }else{
          this.$set(this.mTableList[i],'playIng',false) 
          this.$set(this.mTableList[i],'musicName',this.mTableList[i].musicName.replaceAll(' ',''))
         }
      }
        this.$emit('playMusic',row)
    },
    rowDblclick(row, column, event){
      if(row.state == '-1') return this.$message.error('该歌曲已失效');
      this.throttle(this.playSelectMusic,1000,[row])
     
    },
    getTime(duration){
        let minute = Math.floor(duration / 60);
          let second = Math.floor(duration % 60);
         return  ` ${minute < 10 ? "0" + minute : minute}:${
            second < 10 ? "0" + second : second
          }`;

    },
    tableRowClassName({row, rowIndex}) {
       if(row.state == '-1'){
        return 'el-table-rowState'
       }
        return 'el-table-row';
      }
  },
};
</script>

<style  lang='scss'>
#playListId {
  .el-drawer__open .el-drawer.rtl {
    // opacity: 0.55;
    background: rgb(255 255 255 / 25%);
    margin-top: 60px;
  }
  .title {
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  /* 覆盖 Element UI table 的默认背景色 */
  .el-table,
  .el-table__expanded-cell {
    background-color: transparent !important;
  }
  /* 行高为50px */
  .el-table .cell{
      line-height: 50px;
  }
  /* 去掉白线 */
  .el-table::before{
    height: 0;
  }
  /* 覆盖 table 内单元格的默认背景色 */
  .el-table th,
  .el-table tr,
  .el-table td {
    background-color: transparent !important;
    border: 0; /* 可选，根据需要移除边框 */
  }
  .el-table thead {
    color: #0f0f0f;
    font-weight: 600;
    font-size: 32px;
}
  .el-table  td{
    // color: black;
    background: #f5f7fa;
    cursor: pointer;
    user-select: none;

  }
  .el-table-row{
    // background: #f5f7fa;
    color: black;
    font-size: 28px;
    opacity: 1;
  }
  .el-table-rowState {
    color: #6e6f72;
    font-size: 28px;
  }
  .el-table-row:hover{
    background: #c0cadb30  !important;
  }
 

 .textColor{
    color: #fe564d;
 }
}
</style>